import React from "react";

import {
  AuthContextType,
  emptyUser,
  ForgotPasswordCredentials,
  LocationProps,
  LoginResponseInterface,
  ResetPasswordCredentials,
  SignInCredentials,
  SignUpCredentials,
  storage,
} from "../services/api.interfaces";
import { Navigate, useLocation, useParams } from "react-router-dom";
import {
  postForgotPassword,
  postResetPassword,
  postSignIn,
  postSignUp,
} from "../services/api.calls";

let AuthContext = React.createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  let [user, setUser] = React.useState<LoginResponseInterface>(
    storage.getUser()
  );

  let signUp = (params: SignUpCredentials) => {
    return postSignUp(params).then((res) => {
      setUser({ ...res });
      storage.setUser({ ...res });
    });
  };

  let signIn = (params: SignInCredentials) => {
    return postSignIn(params).then((res) => {
      setUser({ ...res });
      storage.setUser({ ...res });
    });
  };
  let forgotPassword = (params: ForgotPasswordCredentials) => {
    return postForgotPassword(params);
  };
  let resetPassword = (params: ResetPasswordCredentials) => {
    return postResetPassword(params);
  };

  let signOut = () => {
    setUser(emptyUser);
    storage.clearUser();
  };
  let value = {
    user,
    signUp,
    signIn,
    signOut,
    resetPassword,
    forgotPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a authProvider");
  }
  return context;
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let { user } = useAuth();
  let location = useLocation() as unknown as LocationProps;
  const { matchfeedId } = useParams();

  if (!user.token) {
    return (
      <Navigate
        to={
          matchfeedId
            ? `/signup/?matchfeed=${matchfeedId}`
            : location.pathname === "/mingle-replay" || "/mingle-replay/"
            ? "/signin"
            : "/signup"
        }
        state={{
          from: location.pathname,
        }}
      />
    );
  }
  if (matchfeedId) {
    storage.setMatchId(matchfeedId || "");
  }
  if (storage.getMatchId() === "" && location.pathname === "/") {
    return <Navigate to="/getmingle" />;
  }
  return children;
};
