import * as React from "react";

import { styled, useTheme } from "@mui/material/styles";

import { TeamStats } from "../../services/api.interfaces";

import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";

interface Props {
  dataTeam: TeamStats;
}

export const BoxItem = styled(Box)(({ theme }) => ({
  position: "relative",
  background: "rgba(255,255,255,.19)",
  borderRadius: "16px",
  aspectRatio: "1/1",
  width: "98px",
  fontSize: "12px",
  display: "flex",
  textAlign: "left",
  // marginBottom: "16px",
  justifyContent: "space-between",
  padding: "12px",
}));
export const NumberStat = styled(Box)(({ theme }) => ({
  fontSize: "32px",
  fontWeight: 700,
  position: "absolute",
  bottom: "8px",
  right: "8px",
}));

export const MatchData: React.FC<Props> = ({ dataTeam }) => {
  const theme = useTheme();

  return (
    <>
      <Typography
        variant="body1"
        gutterBottom
        sx={{ fontWeight: 700, marginBottom: "32px" }}
      >
        You covered{" "}
        <span style={{ color: "#68F3CB", fontSize: "28px" }}>
          {/*{dataTeam?.matchesStatistics?.played}*/}
          {Number(dataTeam?.matchesStatistics.won) +
            Number(dataTeam?.matchesStatistics.draw) +
            Number(dataTeam?.matchesStatistics.lost)}
        </span>{" "}
        matches in Mingle Sport.
      </Typography>
      <Stack
        flexWrap={"wrap"}
        direction="row"
        spacing={1}
        sx={{ marginBottom: "8px" }}
      >
        <BoxItem>
          Won <NumberStat>{dataTeam?.matchesStatistics.won}</NumberStat>
        </BoxItem>
        <BoxItem>
          Draw <NumberStat>{dataTeam?.matchesStatistics.draw}</NumberStat>
        </BoxItem>
        <BoxItem>
          Lost <NumberStat>{dataTeam?.matchesStatistics.lost}</NumberStat>
        </BoxItem>
      </Stack>
      <Stack
        flexWrap={"wrap"}
        direction="row"
        spacing={1}
        sx={{ marginBottom: "16px" }}
      >
        <BoxItem sx={{ width: "calc(50% - 4px)" }}>
          Total goals
          <NumberStat>{dataTeam?.matchesStatistics.totalGoals}</NumberStat>
        </BoxItem>
        <BoxItem sx={{ width: "calc(50% - 4px)" }}>
          Average goals per match
          <NumberStat>
            {dataTeam?.matchesStatistics.averageGoalsPerMatch}
          </NumberStat>
        </BoxItem>
      </Stack>
      <Typography variant="body2" gutterBottom sx={{ fontWeight: 700 }}>
        You received{" "}
        <span style={{ color: "#68F3CB" }}>
          {dataTeam?.matchesStatistics.totalCheers}
        </span>{" "}
        cheers from
        <span style={{ color: "#68F3CB" }}>
          {dataTeam?.matchesStatistics.totalViews}
        </span>{" "}
        viewers who watched your matches
      </Typography>
    </>
  );
};
