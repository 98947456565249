import * as React from "react";

import { useTheme } from "@mui/material/styles";

import { TeamStats } from "../../services/api.interfaces";
import { Avatar, Stack } from "@mui/material";
import { BoxItem, NumberStat } from "./MatchData";
import {
  IconCheeringStatMingle,
  IconEyeStatMingle,
} from "../brandlibrary/MingleIcons";

interface Props {
  dataTeam: TeamStats;
}

export const Summary: React.FC<Props> = ({ dataTeam }) => {
  const theme = useTheme();

  return (
    <>
      <BoxItem
        sx={{
          width: "100%",
          aspectRatio: "inherit",
          height: "50px",
          marginBottom: "8px",
          marginTop: "22px",
          fontWeight: 700,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{
            backgroundColor: "#fff",
            marginRight: "6px",
            width: "30px",
            height: "30px",
          }}
        >
          <img
            src={
              dataTeam?.logoUrl
                ? dataTeam?.logoUrl
                : dataTeam?.organization?.logoUrl
            }
            style={{ width: 25, height: "auto" }}
          />
        </Avatar>
        {dataTeam?.name}
      </BoxItem>
      <BoxItem
        sx={{
          width: "100%",
          aspectRatio: "inherit",
          height: "50px",
          marginBottom: "8px",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
          color: "#ADADB8",
        }}
      >
        Matches in Mingle{" "}
        <span
          style={{
            fontSize: "32px",
            fontWeight: 700,
            color: "#68F3CB",
            padding: "10px",
          }}
        >
          {Number(dataTeam?.matchesStatistics.won) +
            Number(dataTeam?.matchesStatistics.draw) +
            Number(dataTeam?.matchesStatistics.lost)}
        </span>
      </BoxItem>
      <Stack
        flexWrap={"wrap"}
        direction="row"
        spacing={1}
        sx={{ marginBottom: "8px" }}
      >
        <BoxItem>
          Won{" "}
          <NumberStat sx={{ color: "#68F3CB" }}>
            {dataTeam?.matchesStatistics.won}
          </NumberStat>
        </BoxItem>
        <BoxItem>
          Draw{" "}
          <NumberStat sx={{ color: "#68F3CB" }}>
            {dataTeam?.matchesStatistics.draw}
          </NumberStat>
        </BoxItem>
        <BoxItem>
          Lost{" "}
          <NumberStat sx={{ color: "#68F3CB" }}>
            {dataTeam?.matchesStatistics.lost}
          </NumberStat>
        </BoxItem>
      </Stack>
      <BoxItem
        sx={{
          width: "100%",
          aspectRatio: "inherit",
          height: "50px",
          marginBottom: "8px",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: 700,
          color: "#ADADB8",
        }}
      >
        Total goals{" "}
        <span
          style={{
            fontSize: "32px",
            fontWeight: 700,
            color: "#68F3CB",
            padding: "10px",
          }}
        >
          {dataTeam?.matchesStatistics.totalGoals}
        </span>
      </BoxItem>
      <Stack
        flexWrap={"wrap"}
        direction="row"
        spacing={1}
        sx={{ marginBottom: "16px" }}
      >
        <BoxItem
          sx={{
            width: "calc(50% - 4px)",
            height: "80px",
            aspectRatio: "inherit",
          }}
        >
          Cheers
          <NumberStat sx={{ left: "8px" }}>
            {dataTeam?.matchesStatistics.totalCheers}
          </NumberStat>
          <IconCheeringStatMingle
            sx={{ fontSize: "50px", color: "#0E0E10", opacity: 0.34 }}
          />
        </BoxItem>
        <BoxItem
          sx={{
            width: "calc(50% - 4px)",
            height: "80px",
            aspectRatio: "inherit",
          }}
        >
          Views
          <NumberStat sx={{ left: "8px" }}>
            {dataTeam?.matchesStatistics.totalViews}
          </NumberStat>
          <IconEyeStatMingle
            sx={{ fontSize: "50px", color: "#0E0E10", opacity: 0.34 }}
          />
        </BoxItem>
      </Stack>
    </>
  );
};
