import * as React from "react";
import { useCallback, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  LogoMingle,
  LogoTextMingle,
} from "../components/brandlibrary/MingleIcons";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Theme,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import gradient from "../images/gradient-top-header-2.png";
import backgroundCard from "../images/card-background.png";
import msReplayBadgeLarge from "../images/ms-replay-badge-large.png";
import msReplayBadgeSmall from "../images/ms-replay-badge-small.png";
import logoMadeWith from "../images/logo-made-with.png";
import { useParams } from "react-router-dom";
import { CardShare, TeamStats } from "../services/api.interfaces";
import { MixPanel } from "../services/mixPanel";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Grid, Pagination } from "swiper";
import {
  FirstScore,
  FirstSharedPicture,
  Intro,
  LeaderBoard,
  MatchData,
  MostVotedUser,
  Summary,
} from "../components/stats";
import { Players } from "../components/stats/Players";
import { MatchRating } from "../components/stats/MatchRating";

import useGetTeamReplay from "../hooks/useGetTeamReplay";
import { MostPopularPictures } from "../components/stats/MostPopularPictures";
//https://github.com/niklasvh/html2canvas/issues/1064
import html2canvas from "../utils/html2canvas";
import { MatchTop } from "../components/stats/MatchTop";
import LinearProgress from "@mui/material/LinearProgress";

interface Props {
  children: any;
  card: CardShare;
  dataTeam: TeamStats;
}

const SharedImageCard: React.FC<Props> = React.forwardRef((props) => {
  const { ref, showMainTitleImage, showBottomBadges, cardType } = props.card;

  return (
    <Stack
      ref={ref}
      spacing={2}
      alignContent="center"
      alignSelf="center"
      alignItems="center"
      // justifyContent="space-evenly"
      sx={{
        position: "relative",
        width: "350px",
        height: "622px",
        background: `#000 url(${backgroundCard}) top center no-repeat`,
        // borderRadius: "32px",
        boxSizing: "border-box",
        padding: "20px",
      }}
    >
      {showMainTitleImage && (
        <img
          src={msReplayBadgeLarge}
          style={{ width: "80%", marginTop: "40px" }}
        />
      )}
      {showBottomBadges && (
        <Stack
          direction={"row"}
          alignContent="center"
          alignSelf="center"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            position: "absolute",
            bottom: "25px",
            width: "calc(100% - 40px)",
            left: "20px",
            zIndex: 10,
          }}
        >
          <img src={logoMadeWith} style={{ width: "70px", height: "auto" }} />
          <Button
            sx={{
              pointerEvents: "none",
              fontSize: "12px",
              height: "32px",

              whiteSpace: "nowrap",
              background: "rgba(255,255,255, .19)",
              color: "#fff",
            }}
            size="small"
            startIcon={
              props.dataTeam?.organization ? (
                <Avatar
                  sx={{
                    backgroundColor: "#fff",
                    marginRight: "6px",
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <img
                    src={
                      props.dataTeam.logoUrl
                        ? props.dataTeam.logoUrl
                        : props.dataTeam.organization?.logoUrl
                    }
                    style={{ width: 25, height: "auto" }}
                  />
                </Avatar>
              ) : (
                <Avatar
                  sx={{
                    backgroundColor: "#fff",
                    marginRight: "6px",
                    width: "20px",
                    height: "20px",
                  }}
                >
                  <img
                    src={
                      props.dataTeam.logoUrl
                        ? props.dataTeam.logoUrl
                        : props.dataTeam.organization?.logoUrl
                    }
                    style={{ width: 25, height: "auto" }}
                  />
                </Avatar>
              )
            }
            variant={"contained"}
          >
            {props.dataTeam?.nameAbbreviation}
          </Button>
          <img
            src={msReplayBadgeSmall}
            style={{ width: "85px", height: "16" }}
          />
        </Stack>
      )}
      {props.children}
    </Stack>
  );
});

const TeamReplay: React.FC = () => {
  const { teamId } = useParams();
  const { dataTeamReplay, isLoading } = useGetTeamReplay(teamId || "");
  const [activeCard, setActiveCard] = useState<number>(0);
  const [cardCreateLoading, setCardCreateLoading] = useState<boolean>(false);

  const matchesMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const matchesIpad = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  React.useEffect(() => {
    MixPanel.track("mingleReplay_teamView_view_page_mingleReplay");
  }, []);
  const shareButton = async () => {
    setCardCreateLoading(true);
    MixPanel.track("mingleReplay_teamView_tap_button_share");
    if (cards[activeCard].ref.current === null) {
      return;
    }
    const canvas = await html2canvas(cards[activeCard].ref.current, {
      proxy: "https://imageproxy.minglesports.workers.dev/",
    });

    const dataUrl = canvas.toDataURL();
    const blob = await (await fetch(dataUrl)).blob();
    // const stats1File: any = await toBlob(cards[activeCard].ref.current, {
    //   width: 350,
    //   height: 622,
    // });
    //single image share (or download when share not available)
    const data = {
      // url: window.location.href,
      //   title: "",
      //   text: "Mingle sport - Team Statistics",
      files: [
        new File([blob], "mingle-team-stats1.png", {
          type: "image/png",
          lastModified: new Date().getTime(),
        }),
      ],
    };
    if (navigator.canShare && navigator.canShare({ files: data.files })) {
      try {
        setCardCreateLoading(false);
        await navigator.share(data);
      } catch (err) {}
    } else {
      const link = document.createElement("a");
      link.download = "mingle-team-stats1.png";
      link.href = dataUrl;
      link.click();
      setCardCreateLoading(false);
    }
  };

  const cards: CardShare[] = [
    {
      cardType: "intro",
      showMainTitleImage: true,
      showBottomBadges: false,
      title: <>{dataTeamReplay && <Intro dataTeam={dataTeamReplay} />}</>,

      ref: React.createRef(),
    },
    {
      cardType: "players",
      showMainTitleImage: false,
      showBottomBadges: true,
      title: (
        <>
          {dataTeamReplay && dataTeamReplay?.matchesStatistics && (
            <Players dataTeam={dataTeamReplay} />
          )}
        </>
      ),

      ref: React.createRef(),
    },
    {
      cardType: "matchData",
      showMainTitleImage: false,
      showBottomBadges: true,
      title: (
        <>
          {dataTeamReplay && dataTeamReplay?.matchesStatistics && (
            <MatchData dataTeam={dataTeamReplay} />
          )}
        </>
      ),
      ref: React.createRef(),
    },
    {
      cardType: "firstGoal",
      showMainTitleImage: false,
      showBottomBadges: true,
      showCard: dataTeamReplay?.matchesStatistics?.firstGoal !== null,
      title: (
        <>
          {dataTeamReplay && dataTeamReplay?.matchesStatistics && (
            <FirstScore dataTeam={dataTeamReplay} />
          )}
        </>
      ),

      ref: React.createRef(),
    },
    {
      cardType: "firstPictureSharedUrl",
      showMainTitleImage: false,
      showBottomBadges: true,
      showCard:
        dataTeamReplay?.matchesStatistics?.firstPictureSharedUrl !== null,
      title: (
        <>
          {dataTeamReplay && dataTeamReplay?.matchesStatistics && (
            <FirstSharedPicture dataTeam={dataTeamReplay} />
          )}
        </>
      ),

      ref: React.createRef(),
    },
    {
      cardType: "mostPopularPictures",
      showMainTitleImage: false,
      showBottomBadges: true,
      showCard: dataTeamReplay?.matchesStatistics?.mostCheersPictureUrls
        ? dataTeamReplay?.matchesStatistics?.mostCheersPictureUrls?.length > 0
        : false,
      title: (
        <>
          {dataTeamReplay && dataTeamReplay?.matchesStatistics && (
            <MostPopularPictures dataTeam={dataTeamReplay} />
          )}
        </>
      ),

      ref: React.createRef(),
    },
    {
      cardType: "matchRating",
      showMainTitleImage: false,
      showBottomBadges: true,
      title: <>{dataTeamReplay && <MatchRating dataTeam={dataTeamReplay} />}</>,

      ref: React.createRef(),
    },

    {
      cardType: "goalLeaderBoard",
      showMainTitleImage: false,
      showBottomBadges: true,
      // showCard: dataTeamReplay?.leaderboards?.goals
      //   ? dataTeamReplay?.leaderboards?.goals?.length > 0
      //   : false,
      title: (
        <>
          {dataTeamReplay && dataTeamReplay?.leaderboards.goals && (
            <LeaderBoard
              dataLeaderBoard={dataTeamReplay?.leaderboards.goals}
              title={
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontWeight: 700 }}
                >
                  The leaderboard for{" "}
                  <span style={{ color: "#68F3CB" }}>goals</span> was lead by
                </Typography>
              }
            />
          )}
        </>
      ),
      ref: React.createRef(),
    },
    {
      cardType: "assistLeaderBoard",
      showMainTitleImage: false,
      showBottomBadges: true,
      // showCard: dataTeamReplay?.leaderboards?.assists
      //   ? dataTeamReplay?.leaderboards?.assists?.length > 0
      //   : false,
      title: (
        <>
          {dataTeamReplay && dataTeamReplay?.leaderboards.assists && (
            <LeaderBoard
              dataLeaderBoard={dataTeamReplay?.leaderboards.assists}
              title={
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontWeight: 700 }}
                >
                  The most <span style={{ color: "#68F3CB" }}>assists</span>{" "}
                  came from these players{" "}
                </Typography>
              }
            />
          )}
        </>
      ),
      ref: React.createRef(),
    },
    {
      cardType: "matcheMostViews",
      showMainTitleImage: false,
      showBottomBadges: true,

      title: (
        <>
          {dataTeamReplay && (
            <>
              <MatchTop
                title={
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    The match with the
                    <span style={{ color: "#68F3CB", display: "block" }}>
                      {" "}
                      most views
                    </span>{" "}
                  </Typography>
                }
                showViewsCount={true}
                dataMatch={dataTeamReplay?.matchesStatistics.mostViewedMatch}
              />
            </>
          )}
        </>
      ),
      ref: React.createRef(),
    },
    {
      cardType: "matchMostViews",
      showMainTitleImage: false,
      showBottomBadges: true,
      title: (
        <>
          {dataTeamReplay && (
            <>
              <MatchTop
                title={
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    The match with the
                    <span style={{ color: "#68F3CB", display: "block" }}>
                      {" "}
                      biggest win{" "}
                    </span>{" "}
                  </Typography>
                }
                dataMatch={dataTeamReplay?.matchesStatistics.biggestWinMatch}
              />
            </>
          )}
        </>
      ),
      ref: React.createRef(),
    },
    {
      cardType: "mvp",
      showMainTitleImage: false,
      showCard: dataTeamReplay?.votingResults?.mostVotedUsers
        ? dataTeamReplay?.votingResults?.mostVotedUsers[0]?.users?.length > 0
        : false,
      showBottomBadges: true,
      title: (
        <>
          {dataTeamReplay && (
            <>
              <MostVotedUser
                title={
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    The top 3 players with the <br />
                    most
                    <span style={{ color: "#68F3CB" }}> MVP</span> votes{" "}
                  </Typography>
                }
                type="mvp"
                teamName={dataTeamReplay?.shortName}
                users={
                  dataTeamReplay?.votingResults?.mostVotedUsers
                    ? dataTeamReplay?.votingResults.mostVotedUsers[0].users
                    : null
                }
              />
            </>
          )}
        </>
      ),
      ref: React.createRef(),
    },
    {
      cardType: "playmaker",
      showMainTitleImage: false,
      showBottomBadges: true,
      showCard: dataTeamReplay?.votingResults.mostVotedUsers
        ? dataTeamReplay?.votingResults.mostVotedUsers[2]?.users?.length > 0
        : false,
      title: (
        <>
          {dataTeamReplay && (
            <>
              <MostVotedUser
                title={
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    The top 3 players with the most{" "}
                    <span style={{ color: "#68F3CB" }}>Playmaker</span> votes{" "}
                  </Typography>
                }
                type="playmaker"
                teamName={dataTeamReplay?.shortName}
                users={
                  dataTeamReplay?.votingResults?.mostVotedUsers
                    ? dataTeamReplay?.votingResults?.mostVotedUsers[2].users
                    : null
                }
              />
            </>
          )}
        </>
      ),
      ref: React.createRef(),
    },
    {
      cardType: "workhorse",
      showMainTitleImage: false,
      showBottomBadges: true,
      showCard: dataTeamReplay?.votingResults.mostVotedUsers
        ? dataTeamReplay?.votingResults.mostVotedUsers[1]?.users?.length > 0
        : false,
      title: (
        <>
          {dataTeamReplay && (
            <>
              <MostVotedUser
                title={
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontWeight: 700 }}
                  >
                    The top 3 players with the most{" "}
                    <span style={{ color: "#68F3CB" }}>Workhorse</span> votes{" "}
                  </Typography>
                }
                type="workhorse"
                teamName={dataTeamReplay?.shortName}
                users={
                  dataTeamReplay?.votingResults?.mostVotedUsers
                    ? dataTeamReplay?.votingResults?.mostVotedUsers[1].users
                    : null
                }
              />
            </>
          )}
        </>
      ),
      ref: React.createRef(),
    },
    {
      cardType: "summary",
      showMainTitleImage: true,
      showBottomBadges: true,
      title: (
        <>
          {dataTeamReplay && dataTeamReplay?.matchesStatistics && (
            <Summary dataTeam={dataTeamReplay} />
          )}
        </>
      ),
      ref: React.createRef(),
    },
    // {
    //   cardType: "end",
    //   showMainTitleImage: true,
    //   showBottomBadges: true,
    //   title: (
    //     <Stack spacing={4} sx={{ marginTop: "30px" }}>
    //       <Typography
    //         variant="body1"
    //         gutterBottom
    //         sx={{ fontWeight: 700, marginBottom: "20px" }}
    //       >
    //         Bedankt dat jullie onderdeel waren van ons eerste seizoen bij
    //         Mingle!
    //       </Typography>
    //       <Typography
    //         variant="body1"
    //         gutterBottom
    //         sx={{ fontWeight: 700, marginBottom: "20px" }}
    //       >
    //         We zijn trots dat jullie ons hebben gebruikt, en we zijn constant
    //         bezig om onze app nog leuker voor jullie te maken.
    //       </Typography>
    //       <Typography
    //         variant="body1"
    //         gutterBottom
    //         sx={{ fontWeight: 700, marginBottom: "20px" }}
    //       >
    //         We zien jullie graag terug in jullie volgende seizoen!
    //       </Typography>
    //     </Stack>
    //   ),
    //
    //   ref: React.createRef(),
    // },
  ];
  const downLoadButton = useCallback(async () => {
    setCardCreateLoading(true);
    MixPanel.track("mingleReplay_teamView_tap_button_download");
    cards.map(async (card: CardShare, index) => {
      const canvas = await html2canvas(card.ref.current, {
        proxy: "https://imageproxy.minglesports.workers.dev/",
      });

      const dataUrl = canvas.toDataURL();

      const link = document.createElement("a");
      link.download = `mingle-replay-${index}.png`;
      link.href = dataUrl;
      link.click();
      setCardCreateLoading(false);
      // toPng(card.ref.current as HTMLElement, {
      //   cacheBust: true,
      //   canvasWidth: 350,
      //   canvasHeight: 622,
      // })
      //   .then((dataUrl) => {
      //     const link = document.createElement("a");
      //     link.download = `mingle-replay-${index}.png`;
      //     link.href = dataUrl;
      //     link.click();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    });
  }, [cards, dataTeamReplay]);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        background: `#000 url(${gradient}) top center no-repeat`,
        backgroundSize: `${matchesMobile ? 600 : 1000}px 545px`,
      }}
    >
      <Toolbar
        sx={{
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);",
          height: "80px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            backgroundColor: "#68F3CB",
            borderRadius: matchesMobile ? "12px" : "16px",
            height: matchesMobile ? "32px" : "48px",
            width: matchesMobile ? "32px" : "48px",
            padding: matchesMobile ? "6px" : "12px",
            lineHeight: matchesMobile ? "20px" : "24px",
            marginRight: "16px",
            marginLeft: 0,
          }}
        >
          <LogoMingle
            sx={{
              color: "#0B1ECF",
              fontSize: matchesMobile ? "20px" : "24px",
              lineHeight: matchesMobile ? "20px" : "24px",
              textDecoration: "none",
            }}
          />
        </div>
        {!matchesMobile && (
          <LogoTextMingle
            width="181"
            height="29"
            sx={{
              color: "#fff",
              height: "29px",
              fontSize: "181px",
              margin: "0 20px 0 0",
              textDecoration: "none",
            }}
          />
        )}
        <Button
          size="small"
          component="a"
          href="https://homebase.mingle.sport/"
          color="primary"
          variant="outlined"
          sx={{
            boxShadow: "none",
            marginLeft: "auto",
            whiteSpace: "nowrap",
            background: "#fff",
            marginRight: "1rem",
            border: "none",

            "&:hover": { background: "#fff", border: "none" },
          }}
          onClick={() => {
            MixPanel.track(
              "mingleReplay_teamView_tap_button_seeMoreStatsHeader"
            );
          }}
        >
          See more stats
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          component="a"
          href="https://mingle.sport/download-instructions/"
          sx={{
            marginLeft: "10px",
            borderWidth: "2px",
            whiteSpace: "nowrap",
            "&:hover": { borderWidth: "2px" },
          }}
        >
          Get Mingle
        </Button>
      </Toolbar>

      <Container maxWidth="xl" sx={{ paddingBottom: "132px" }}>
        <Stack
          spacing={4}
          alignContent="center"
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
          sx={{}}
        >
          <Typography
            variant="h1"
            color="#fff"
            sx={{
              fontSize: matchesMobile ? "32px" : "72px",
              fontWeight: 700,
              textAlign: matchesMobile ? "center" : "left",
            }}
          >
            Mingle Sport Replay
          </Typography>
          <Typography
            variant="body1"
            color="#fff"
            sx={{
              textAlign: "center",
              maxWidth: "500px",
            }}
          >
            Relive your 2024 highlights and stats with Mingle Sport Replay.
          </Typography>

          <Box
            sx={{
              "& .swiper-pagination-bullet": {
                border: "5px #fff solid",
              },
            }}
          >
            {dataTeamReplay ? (
              <>
                <Swiper
                  slidesPerGroup={matchesMobile ? 1 : matchesIpad ? 2 : 3}
                  slidesPerView={matchesMobile ? 1 : matchesIpad ? 2 : 3}
                  grid={{
                    rows: 1,
                  }}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Grid, Pagination]}
                  style={{
                    width: matchesMobile
                      ? "350px"
                      : matchesIpad
                      ? "750px"
                      : "1110px",
                    height: "670px",
                  }}
                  onSlideChangeTransitionEnd={(swiper) => {
                    MixPanel.track(
                      "mingleReplay_teamView_swipe_content_images"
                    );
                  }}
                  onSlideChange={(swiper) => {
                    setActiveCard(swiper.activeIndex);
                  }}
                >
                  {dataTeamReplay && (
                    <>
                      {cards.map((card, index) => {
                        if (card.showCard === false) {
                          return null;
                        }
                        return (
                          <SwiperSlide key={"card" + index}>
                            <SharedImageCard
                              card={card}
                              dataTeam={dataTeamReplay}
                            >
                              <div
                                style={{ color: "#fff", textAlign: "center" }}
                              >
                                {card.title}
                              </div>
                            </SharedImageCard>
                          </SwiperSlide>
                        );
                      })}
                    </>
                  )}
                </Swiper>
                {!dataTeamReplay && (
                  <Typography
                    variant="body1"
                    textAlign="center"
                    gutterBottom
                    sx={{ color: "#fff", fontWeight: "700" }}
                  >
                    Unfortunately, no statistics are available (yet).
                  </Typography>
                )}
                <Stack
                  spacing={5}
                  alignContent="center"
                  alignSelf="center"
                  alignItems="center"
                >
                  {matchesMobile ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={shareButton}
                      sx={{
                        position: "fixed",
                        bottom: "20px",
                        zIndex: "100",
                        width: "90%",
                      }}
                      endIcon={
                        cardCreateLoading && (
                          <IconButton aria-label="" edge="end">
                            <CircularProgress
                              size={15}
                              sx={{ color: "#68F3CB" }}
                              color="success"
                            />
                          </IconButton>
                        )
                      }
                    >
                      Share
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={downLoadButton}
                      endIcon={
                        cardCreateLoading && (
                          <IconButton aria-label="" edge="end">
                            <CircularProgress
                              size={15}
                              sx={{ color: "#68F3CB" }}
                              color="success"
                            />
                          </IconButton>
                        )
                      }
                    >
                      Download images
                    </Button>
                  )}
                  <Button
                    size="small"
                    component="a"
                    href="https://homebase.mingle.sport/"
                    color="primary"
                    variant="outlined"
                    sx={{
                      boxShadow: "none",
                      marginLeft: "auto",
                      whiteSpace: "nowrap",
                      background: "#fff",
                      marginRight: "1rem",
                      border: "none",

                      "&:hover": { background: "#fff", border: "none" },
                    }}
                    onClick={() => {
                      MixPanel.track(
                        "mingleReplay_teamView_tap_button_seeMoreStatsBottom"
                      );
                    }}
                  >
                    See more stats
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                {isLoading ? (
                  <>
                    <Stack
                      spacing={2}
                      alignContent="center"
                      alignSelf="center"
                      alignItems="center"
                      // justifyContent="space-evenly"
                      sx={{}}
                    >
                      <LinearProgress
                        sx={{ width: "100%" }}
                        variant="determinate"
                        value={progress}
                        color="success"
                      />
                      <Typography
                        variant="body1"
                        color="#fff"
                        sx={{
                          marginTop: "80px",
                          textAlign: "center",
                          maxWidth: "500px",
                          fontWeight: 700,
                        }}
                      >
                        Stats are loading...
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <Stack
                    spacing={2}
                    alignContent="center"
                    alignSelf="center"
                    alignItems="center"
                    // justifyContent="space-evenly"
                    sx={{
                      position: "relative",
                      width: "350px",
                      height: "622px",
                      background: `#000 url(${backgroundCard}) top center no-repeat`,
                      // borderRadius: "32px",
                      boxSizing: "border-box",
                      padding: "20px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="#fff"
                      sx={{
                        marginTop: "80px",
                        textAlign: "center",
                        maxWidth: "500px",
                        fontWeight: 700,
                      }}
                    >
                      Unfortunately we could not get your team data.
                    </Typography>
                    <Typography
                      variant="body2"
                      color="#fff"
                      sx={{
                        marginTop: "80px",
                        textAlign: "center",
                        maxWidth: "500px",
                      }}
                    >
                      {" "}
                      You can see all your stats on Homebase
                    </Typography>
                    <Button
                      size="small"
                      component="a"
                      href="https://homebase.mingle.sport/"
                      color="primary"
                      variant="outlined"
                      sx={{
                        boxShadow: "none",
                        marginLeft: "auto",
                        whiteSpace: "nowrap",
                        background: "#fff",
                        marginRight: "1rem",
                        border: "none",

                        "&:hover": { background: "#fff", border: "none" },
                      }}
                    >
                      Log in to Homebase
                    </Button>
                  </Stack>
                )}
              </>
            )}
          </Box>
        </Stack>
      </Container>
    </div>
  );
};
export default TeamReplay;
