import { useQuery } from "@tanstack/react-query";
import { TeamStats } from "../services/api.interfaces";
import axios from "axios";

const fetchGetTeamReplay = (teamId: string): Promise<TeamStats> => {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_WEBAPPI_URL}/Team/statistics/${teamId}?from=2024-01-01T12%3A49%3A08.000Z&to=2024-12-31T12%3A49%3A08.000Z`,
  }).then<TeamStats>((res) => res.data);

  //2022-01-01T12:49:08.000Z
  //2022-12-31T12:49:08.000Z
};
export default function useGetTeamReplay(teamId: string) {
  const queryTeamReplay = useQuery(
    ["getTeamStats"],
    () => fetchGetTeamReplay(teamId),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  return {
    ...queryTeamReplay,
    dataTeamReplay: queryTeamReplay.data,
    teamReplayIsLoading: queryTeamReplay.isLoading,
  };
}
